import React, { Component } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import { StyleSheet, css } from 'aphrodite'
import Scroll from 'react-scroll'
import Helmet from 'react-helmet'

import AltertineLayout from './layouts/AltertineLayout'

import Button from '@material-ui/core/Button'

import Classes from '../styles/classes'
import i18next from '../config/i18n/context'

import { withNamespaces } from 'react-i18next'

const styles = StyleSheet.create({
  footer: {
    backgroundColor: '#002337'
  },
  button: {
    display: 'block',
    margin: '1em 0'
  }
})

const IndexPage = ({ t }) => {
  const lang =
    i18next.language ||
    (typeof window !== 'undefined' && window.localStorage.i18nextLng)
  console.log('language home', lang)
  return (
    <AltertineLayout image="/assets/img/bar-restaurant.jpeg">
      <Helmet
        title={t('restaurant.meta.title')}
        meta={[
          {
            name: 'description',
            content:t('restaurant.meta.description')
          },
          { name: 'charset', content: 'UTF-8' }
        ]}
      />
      <section className="project_descr">
        <div className="container mt-100">
          <div className="row center">
            <div className="col-md-8 col-md-offset-2 col-sm-12">
              <div className="section-title">
                <h2 className="mb-50">{t('restaurant.title')}</h2>
                <p className="module-subtitle">
                  {t('restaurant.description')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </AltertineLayout>
  )
}

export default withNamespaces('home')(IndexPage)
